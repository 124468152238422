<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Retailer</label>
      <div class="col-2">
        <select v-model="form.merchantType" class="form-select">
          <option v-for="(item, index) in retailerMap" :key="index" :value="item">{{item}}</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Account</label>
      <div class="col-3">
            <input type="text" v-model="form.account" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">First Name</label>
      <div class="col-3">
            <input type="text" v-model="form.firstName" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Last Name</label>
      <div class="col-3">
            <input type="text" v-model="form.lastName" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Password</label>
      <div class="col-3">
            <input type="text" v-model="form.password" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Phone</label>
      <div class="col-3">
            <input type="text" v-model="form.phone" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Reward Number</label>
      <div class="col-3">
            <input type="text" v-model="form.reward" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Status</label>
      <div class="col-2">
          <select v-model="form.status" class="form-select">
            <option v-for="(item, index) in statusList" :key="index" :value="item">{{item}}</option>
          </select>
      </div>
    </div>
    <div class="mb-3 row" v-if="this.form.id">
      <label class="col-1 col-form-label">Credit Card</label>
      <div class="col-3">
          <select v-model="assocCreditCard" class="form-select">
            <option v-for="(item, index) in creditCardList" :key="index" :value="item.creditCard">{{item.creditCard + " " + item.name}}</option>
          </select>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
    <CardTable :tableBody="assocCreditCardList"></CardTable>
  </div>
</template>

<script>
import axios from "axios"
import { encode, decode } from "../util.js"

var getContent =  function(item) {
  var content = item.name.toLowerCase().indexOf("auth") > -1 ? "<div>" + item.creditCard + "</div>" : "<div><a target='#' href='/#/creditcard/bill?creditcard=" + item.creditCard + "'>" + item.creditCard + "</a><div>";
  if (item.month && item.year) {
    content += item.month + "/" + item.year + " ";
  }
  if (item.pin) {
    content += item.pin + "</div>";
  }
  return content;
}

export default {
  name: "AccountEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id || "",
        merchantType: "Best Buy",
        status: "Valid"
      },
      creditCardList: [],
      breadcrumbs: [{ name: "Account", link: "/account" }, { name: (this.$route.query.id ? "Update" : "New") + " Account" }],
      alertContent: "",
      alertClass: "alert-danger",
      retailerMap: ["Amazon", "Best Buy", "Target", "Sams Club", "Sephora", "eBay", "Staples"],
      statusList: ["New", "Valid", "Unknown", "Blacklisted"],
      assocCreditCardList: [],
      assocCreditCard: "",
      currentId: "",
      showModal: false
    };
  },
  mounted() {
      if (this.form.id) {
         this.getObject();
      }
  },
  methods: {
    openModal(id) {
      this.showModal = true;
      this.currentId = id;
    },
    confirmModal() {
      var unlinkCard = this.currentId;
      this.form.creditCard = this.form.creditCard.filter(item => item && item.length > 0).map(item => decode(item)).filter(item => item !== unlinkCard).map(item => encode(item));
      this.formSubmit();
      this.showModal = false;
    },
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      this.form.password = encode(this.form.password);
      this.form.phone = encode(this.form.phone);
      if (!this.form.creditCard) {
        this.form.creditCard = []
      }
      if (this.assocCreditCard) {
        this.form.creditCard.push(encode(this.assocCreditCard));
      }
      axios.post("/account", this.form)
        .then(() => {
          this.submitted = false;
          this.alertContent = "Account has been saved successfully";
          this.alertClass = "alert-success";
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
          this.submitted = false;
        });
    },
    getObject() {
      var openModal = this.openModal;
      axios.get("/account/" + this.form.id)
        .then((response) => {
          this.form = response.data.account;
          this.form.password = decode(this.form.password);
          this.form.phone = decode(this.form.phone);
          var meCreditCardList = this.form.creditCard.filter(item => item && item.length > 0).map(item => decode(item));
          var usedCreditCardList = response.data.usedCreditCardList.filter(item => item && item.length > 0).map(item => decode(item));
          var creditCardList = response.data.creditCardList.map(item => { return {
            creditCard: decode(item.creditcard), name: item.name, pin: decode(item.pin), month: item.month, year: item.year
          }});

          // used to generate form select
          this.creditCardList = creditCardList.filter(item => usedCreditCardList.indexOf(item.creditCard) === -1);
          // used to generate credit card table
          this.assocCreditCardList = creditCardList.filter(item => meCreditCardList.indexOf(item.creditCard) > -1).map(item => {
              return {
                title: item.name,
                content: getContent(item),
                footer: {type: "button", click: function() { openModal(item.creditCard) }, name: "<span class='fa fa-unlink'></span>", class: "btn-danger"}
              }
          });
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
        })
    }
  }
};
</script>